$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
$Font: var(--font);
.quotes {
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  .quotes-slider {
    display: flex;
    transform: translateX(0);
    .quote-container {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      .single-quote {
        max-width: 820px;
        margin: 60px 0;
        .title {
          margin-bottom: 40px;
        }
        .description {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.quotes-mob-wrapper {
  padding-top: 75px;
  position: relative;
  &.hint::after {
    content: attr(data-hint);
    position: absolute;
    left: 32px;
    bottom: 60px;
    font-family: $Font;
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: $JustGrey;
    opacity: 0.3;
    z-index: 1;
  }
  .quotes-mob {
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    transition: height 0.3s ease-in;
    margin-bottom: 90px;
    .quote-mob {
      scroll-snap-align: start;
      width: 100vw;
      display: flex;
      justify-content: center;
      .single-quote {
        padding-inline: 32px;
        .title {
          margin-bottom: 30px;
        }
        .description {
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 1201px) and (-webkit-device-pixel-ratio: 1.25) {
  .quotes .quotes-slider .quote-container {
    width: 125vw;
  }
}
@media screen and (max-width: 1536px) and (min-width: 1201px) {}
@media screen and (max-width: 1200px) and (min-width: 481px) {
  .quotes-mob-wrapper {
    padding-top: 0;
    &.hint::after {
      left: 64px;
    }
    .quotes-mob .quote-mob .single-quote {
      padding: 60px 64px;
    }
  }
}
@media screen and (max-width: 1229px) and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  .quotes .quotes-slider .quote-container {
    width: 125vw;
  }
}
@media (max-width: 480px) {
  .quotes-mob-wrapper {
    .quotes-mob {
      margin-bottom: 65px;
    }
    &.hint::after {
      bottom: 55px;
    }
  }
}
