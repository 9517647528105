$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
.industries {
  width: 100%;
  height: 850px;
  overflow: hidden;
  transition: height 0.5s ease;
  position: relative;
  .industries-title {
    position: absolute;
    transition: right 1s ease-out, top 1s ease-out;
    top: 280px;
    right: 50%;
    display: flex;
    // justify-content: flex-end;
    p {
      font-size: 130px;
      font-weight: 300;
      line-height: 140px;
      letter-spacing: 0;
      width: 600px;
      transition: width 1s ease-out;
    }
    &.spread {
      top: 80px;
      right: 0;
      left: 0;
      justify-content: center;
      p {
        width: 1280px;
        word-break: break-all;
        line-break: loose;
      }
    }
  }
  .industries-slider {
    z-index: 1;
    display: flex;
    flex-direction: row;
    transform: translateX(0);
    transition: transform 0.5s ease;
    position: relative;
    user-select: none;
    .interaction-box {
      top: -70px;
      z-index: 999;
      left: -120px;
      width: 600px;
      position: absolute;
      aspect-ratio: 1 / 1.12;
    }
    .shadow-slider {
      top: 0;
      position: absolute;
      display: none;
      flex-direction: row;
      user-select: none;
    }
    &.still {
      transition: none;
      .shadow-slider {
        display: flex;
      }
    }

    .industry-card {
      width: 356px;
      height: 533px;
      position: relative;
      transition: transform 0.5s ease, z-index 0.5s ease;
      transform-origin: center;
      &.spread:hover {
        transform: scale(1.05);
        z-index: 99 !important;
      }
      & * {
        pointer-events: none;
      }
      img {
        top: 0;
        left: 0;
        width: 356px;
        height: 533px;
        position: absolute;
        pointer-events: none;
        user-select: none;
      }
      p {
        top: 30px;
        left: 35px;
        position: absolute;
        font-weight: 500;
        font-size: 50px;
        line-height: 90%;
        line-break: strict;
        z-index: 1;
        pointer-events: none;
        user-select: none;
      }
    }
  }
  &.large {
    height: 950px;
    .industries-slider .interaction-box {
      display: none;
    }
  }
}
.industries-mob {
  .industries-title {
    padding: 60px 32px 40px;
  }
  .industries-slider {
    display: flex;
    height: 375px;
    overflow-x: scroll;
    flex-direction: row;
    scroll-snap-type: x mandatory;
    .industry-card {
      scroll-snap-align: start;
      width: 100px;
      height: 375px;
      overflow: hidden;
      div {
        width: 375px;
        height: 375px;
        position: relative;
        transform: rotate(-90deg);
        p {
          left: 12px;
          top: 16px;
          font-size: 40px;
          font-weight: 700;
          line-height: 47px;
          position: absolute;
        }
        img {
          width: 375px;
          height: 100px;
          overflow: hidden;
          // object-fit: cover;
        }
      }
    }
  }
}
@media screen and (max-width: 1536px) and (min-width: 1201px) {}
@media screen and (max-width: 1200px) and (min-width: 481px) {
  .industries-mob {
    padding-bottom: 60px;
    .industries-slider {
      .industry-card {
        width: 170px;
        div img {
          height: 170px;
        }
      }
    }
  }
  .industries-title {
    .H-mob {
      font-weight: 300;
    }
  }
}
@media screen and (max-width: 1229px) and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  .industries .industries-title {
    right: 50%;
    left: unset;
  }
}
@media (max-width: 480px) {
}
