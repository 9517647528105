$Blend: var(--white);
.color-white,
.color-after-white::after,
.color-before-white::before {
  color: $Blend;
}
.bg-after-white::after,
.bg-before-white::before,
.bg-white,
.bg-gu-white,
.bg-gd-white,
.hover-bg-white:hover {
  background-color: $Blend;
}
.fill-white {
  fill: $Blend;
  & * {
    fill: $Blend;
  }
}
.scroll-white {
  border-color: $Blend !important;
  &::before {
    background-color: $Blend !important;
  }
}
.fade-down-white {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, $Blend 0%, transparent 100%);
  }
}
.border-color-white {
  border-color: $Blend;
}
.hover-border-color-white:hover ~ div.labels {
  &::before,
  &::after {
    border: 1px solid $Blend;
  }
}
