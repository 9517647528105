$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
.calculator {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .button {
    width: 190px;
    padding: 20px 65px;
    border: 2px solid $JustGrey;
    position: relative;
    &:hover {
      background: $LightGreen;
      color: $NavyGreen;
      border: 2px solid $NavyGreen;
    }
    &.selected {
      background: $NavyGreen;
      color: $White;
      border: 2px solid $NavyGreen;
    }
  }
  .buttons-container {
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
  }
  &-item {
    width: 610px;
    margin-top: 60px;
    margin-bottom: 60px;
    & > div {
      justify-content: space-between;
    }
  }
  &-check {
    opacity: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
  }
}

@media screen and (max-width: 1536px) and (min-width: 1201px) {
}
@media screen and (max-width: 1200px) and (min-width: 481px) {
}
@media (max-width: 480px) {
}
