:root {
  --navy-green: #004d35;
  --blend-a: #f9f3df;
  --blend-b: #c1f8cf;
  --blend-c: #4fd3c4;
  --dark-blend-a: #03a65a;
  --dark-blend-b: #488fb1;
  --dark-blend-c: #533e85;
  --bright-green: #00ffaf;
  --light-green: #d4ffef;
  --navy-blue: #0100e4;
  --bright-blue: #004ce1;
  --light-blue: #85d2fe;
  --soft-blue: #eaf5f9;
  --midnight-blue: #0a0d29;
  --grey-blue: #0a2129;
  --just-grey: #333333;
  --light-grey: #afbcba;
  --yellow: #f9d401;
  --orange: #e47447;
  --white: #ffffff;
  --black: #000000;
  --smoothy: smooth;
  --font: "Commissioner", sans-serif;
  --x: 0px;
  --y: 0px;
  --offset: 0px;
  --p: 0px;
}
body {
  max-width: 100%;
  min-height: 100%;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  section, header, footer {
    zoom: 0.8;
  }
}

html,
body,
main {
  overscroll-behavior-x: none;
}
