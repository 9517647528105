$Blend: var(--navy-blue);
$White: var(--white);
.color-navy-blue {
  color: $Blend;
}
.color-after-navy-blue::after,
.color-before-navy-blue::before {
  color: $Blend;
}
.bg-after-navy-blue::after,
.bg-before-navy-blue::before,
.bg-navy-blue,
.hover-bg-navy-blue:hover {
  background-color: $Blend;
}
.fill-navy-blue {
  fill: $Blend;
  & * {
    fill: $Blend;
  }
}
.bg-gu-navy-blue {
  background: linear-gradient(180deg, $Blend 0%, $White 100%);
}
.bg-gd-navy-blue {
  background: linear-gradient(0deg, $Blend 0%, $White 100%);
}
.scroll-navy-blue {
  border-color: $Blend !important;
  &::before {
    background-color: $Blend !important;
  }
}

.fade-down-navy-blue {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, $Blend 0%, transparent 100%);
  }
}
.border-color-navy-blue {
  border-color: $Blend;
}
.hover-border-color-navy-blue:hover ~ div.labels {
  &::before,
  &::after {
    border: 1px solid $Blend;
  }
}
