$Blend: var(--blend-a);
$White: var(--white);
.color-blend-a {
  color: $Blend;
}
.color-after-blend-a::after,
.color-before-blend-a::before {
  color: $Blend;
}
.bg-after-blend-a::after,
.bg-before-blend-a::before,
.bg-blend-a,
.hover-bg-blend-a:hover {
  background-color: $Blend;
}
.fill-blend-a {
  fill: $Blend;
  & * {
    fill: $Blend;
  }
}
.bg-gu-blend-a {
  background: linear-gradient(180deg, $Blend 0%, $White 100%);
}
.bg-gd-blend-a {
  background: linear-gradient(0deg, $Blend 0%, $White 100%);
}
.scroll-blend-a {
  border-color: $Blend !important;
  &::before {
    background-color: $Blend !important;
  }
}
.fade-down-blend-a {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, $Blend 0%, transparent 100%);
  }
}
.border-color-blend-a {
  border-color: $Blend;
}
.hover-border-color-blend-a:hover ~ div.labels {
  &::before,
  &::after {
    border: 1px solid $Blend;
  }
}
