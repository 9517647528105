$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
$Height: calc(100vh - 50px);
$x: var(--x);
$y: var(--y);
.admin {
  background: hsl(228, 100%, 99.02%);
  cursor: auto;
  pointer-events: all;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  & * {
    cursor: auto;
    pointer-events: all;
  }
  .bar {
    width: 50px;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: width 0.4s ease-out;
    background-color: #f3f4f8;
    &.fixate,
    &:hover {
      width: 330px;
      transition: width 0.2s ease-in;
    }
    .head {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
    }
  }
  p,
  label {
    font-family: sans-serif;
  }
  .admin-tabs {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    transition: transform 0.5s ease;
    .admin-tab {
      width: 100vw;
      height: calc(100vh - 50px);
      overflow-y: scroll;
    }
    .posts-editor {
      padding: 12px;
    }
  }
}
.admin,
.body,
.bar,
.body-box {
  &::-webkit-scrollbar {
    display: none;
  }
}
.body {
  width: 100%;
  max-height: 100%;
  padding: 10px;
}
.body-box {
  width: 100%;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
  padding: 10px;
}
.section-drag {
  background: #3e3e3e;
  border-radius: 15px;
  padding: 7px 12px;
  position: fixed;
}
.form-container {
  // flex: 0 1 700px;
  margin: auto;
  padding: 5px 0;
  .window {
    position: relative;
    background: #3e3e3e;
    border-radius: 15px;
    transition: transform 0.2s ease-in-out;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 12px;
      background: #4d4d4f;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .mini-max {
        display: flex;
        flex-direction: row;
      }
      .virgin {
        text-decoration: line-through;
      }
    }
    .button {
      display: flex;
      min-width: 16px;
      padding: 4px 10px;
      // height: 16px;
      cursor: pointer;
      margin-right: 6px;
      border-radius: 10px;
      background: white;
      justify-content: center;
      align-items: center;
      p {
        font-size: 12px;
        color: inherit;
        font-weight: 600;
        pointer-events: none;
        user-select: none;
      }
      &.svg-icon {
        padding: 2px 8px;
        svg {
          width: 16px;
          path {
            fill: #666;
          }
        }
        &.matt {
          background-color: #3d3d3d;
        }
      }
    }
    .maximize {
      background: #f4be4f;
      color: #8f591d;
    }
    .minimize {
      background: #62c554;
      color: #296017;
    }
    .other {
      background: #d8d8d8;
      color: #666767;
    }
    .close {
      background: #ed6a5d;
      color: #6a1109;
    }
    .body .item {
      flex: 1;
      .row {
        display: flex;
        flex-direction: row;
      }
    }
    &.is-dragging {
      opacity: 50%;
      &.move-up {
        transform: translateY(-44px);
      }
      &.move-down {
        transform: translateY(44px);
      }
      &.move-back {
        transform: translateY(0px);
      }
      &.selected {
        opacity: 0;
      }
    }
  }

  .hidden {
    .body {
      display: none;
    }
    .header {
      border-radius: 15px;
    }
  }
}

.section-form-group {
  flex: 1;
  margin: 0 5px 15px;
  .form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: white;
    outline: none;
    transition: border-color 0.2s;
    font-size: 1rem;
    z-index: 1;
  }
  .form-area-control {
    overflow: auto;
    resize: vertical;
    width: 100%;
    min-height: 100px;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: white;
    outline: none;
    transition: border-color 0.2s;
    font-size: 1rem;
    z-index: 1;
  }
  &.tiny {
    flex: 0.5;
  }
  &:focus {
    border-bottom-color: #ddd;
  }
  p {
    font-size: 0.9rem;
    color: rgb(185, 185, 185);
  }
}
.section-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(231, 225, 198);
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.variant {
  font-size: 1rem;
  font-weight: 400;
  color: rgb(255, 181, 146);
}
.gallery-container {
  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .add {
    width: 30px;
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
  .selected-img {
    margin: 0 5px 15px;
    position: relative;
    .img-placeholder,
    .img {
      background-color: #eee;
      border: #eee 1px solid;
    }
    .img-placeholder,
    img {
      width: 38px;
      aspect-ratio: 1 / 1;
      object-fit: scale-down;
    }
    p {
      font-size: 0.9rem;
      color: rgb(185, 185, 185);
    }
    &:hover .remove {
      position: absolute;
      right: -4px;
      top: 12px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: red;
      z-index: 99;
      &::after,
      &::before {
        content: "";
        top: 7px;
        left: 3px;
        width: 10px;
        height: 2px;
        background-color: white;
        position: absolute;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
.upload-file {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-color: #3e3e3e;
  label {
    text-align: center;
    border-radius: 2%;
    margin-right: 10px;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }
}
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgba(#000000, 0.6);
  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 75px;
    top: 115px;
    transform: rotate(45deg);
    border-radius: 50%;
    z-index: 999;
    background-color: #ed6a5d;
    cursor: pointer;
    &:hover {
      filter: brightness(125%);
    }
  }
}
.gallery {
  gap: 20px;
  padding: 20px;
  margin: 100px 50px 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: white;
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
  border-radius: 16px;

  .card-selected {
    border: #004d35 1px solid;
  }

  .img-box {
    position: relative;
    background-color: #eee;
    width: 150px;
    border-radius: 12px;
    padding: 2px;
    border: #eee 2px solid;
    .img-name {
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      z-index: 2;
      background-color: rgba(white, 0.8);
      transition: opacity 0.2s ease-out;
      word-break: break-all;
    }
    .del {
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: red;
      z-index: 99;
      transition: all 0.3s ease-out 0.3s;
      &::after,
      &::before {
        content: "";
        top: 7px;
        left: 3px;
        width: 10px;
        height: 2px;
        background-color: white;
        position: absolute;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
    &:hover {
      .img-name {
        opacity: 0;
        z-index: -2;
      }
      border-color: #ccc;
      .del {
        opacity: 1;
        right: 6px;
        top: 6px;
      }
      .gallery-card {
        transform: scale(0.9);
      }
    }
  }
  .gallery-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    transition: transform 0.3s ease-out;
    transform: scale(1);
  }
}
.entity-box {
  gap: 8px;
  flex-wrap: wrap;
  .entity {
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px dashed hsl(200, 70%, 90%);
    background-color: hsl(0, 100%, 100%);
    user-select: none;
    &:hover,
    &.selected {
      background-color: hsl(200, 70%, 80%);
    }
  }
}
.variant-editor {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-evenly;
  max-width: 100px;
}
// list
.collapse-list {
  width: 100%;
  border-radius: 4px;
  //filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));
  overflow: hidden;
  .item-box {
    flex: 1;
    height: calc(100% - 80px);
    max-height: calc(100vh - 190px);
    background-color: white;
    overflow-y: auto;
  }
  .remove {
    width: 16px;
    height: 40px;
    display: none;
    color: white;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    background-color: red;
    position: relative;
    &::after {
      display: block;
      content: "";
      width: 60%;
      height: 2px;
      color: white;
      background-color: white;
      position: absolute;
      top: calc(50% - 1px);
      left: 20%;
    }
    &:hover {
      background-color: rgb(189, 0, 0);
    }
  }
  .item {
    height: 40px;
    padding: 10px;
    width: calc(100% - 16px);
    cursor: pointer;
    background-color: white;
    box-shadow: 0 2px 0 white;
    border-radius: 2px;
    p {
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
    }
  }

  .snippet:hover::after {
    content: attr(data-content);
    position: fixed;
    top: calc($y - 40px);
    left: calc($x + var(--offset) - 150px);
    padding: 5px 5px 0;
    box-shadow: 5px 5px 10px #0001;
    background-color: #f7f7f9;
    z-index: 1000;
    border-radius: 4px;
    width: 150px;
    height: max-content;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    &:hover {
      .item {
        background-color: #f7f7f9;
      }
      .remove {
        display: block;
      }
    }
  }
  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f7f9;
    height: 40px;
    input {
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      padding: 10px;
    }
    .add {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 12px;
      font-weight: 700;
      color: white;
      background-color: #82c3af;
      height: 100%;
      border-radius: 12px 12px 0 12px;
      span {
        cursor: pointer;
      }
      &:hover {
        background-color: #39856e;
      }
    }
    .filter-tab {
      height: 100%;
      background-color: white;
      &:hover {
        background-color: hsl(255, 0%, 95%);
      }
      &.active {
        background-color: #82c3af;
      }
    }
  }
  .hidden {
    display: none;
  }
  .selected {
    display: block;
    p {
      color: #004d35;
      font-weight: 600;
    }
  }
}
.login-form {
  position: relative;
  width: 500px;
  margin: 50px auto 100px auto;
  input {
    font-family: sans-serif;
    font-size: 0.875em;
    width: 100%;
    height: 50px;
    padding: 0 15px 0 15px;
    background: transparent;
    outline: none;
    color: #004d35;
    border: solid 1px #004d35;
    transition: all 0.3s ease-in-out;
    &::placeholder {
      color: #004d35;
    }
    &[name="username"] {
      border-bottom: none;
    }
    &:focus {
      color: #0a2129;
      background: #eaf5f9;
    }
  }
  button {
    font-family: sans-serif;
    font-size: 0.875em;
    width: 100%;
    height: 50px;
    padding: 0 15px 0 15px;
    background: transparent;
    outline: none;
    color: #004d35;
    border: solid 1px #004d35;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #004d35;
      color: #fff;
    }
  }
  .submit {
    margin-top: 12px;
    width: 100%;
    padding: 0;
    font-family: sans-serif;
    font-size: 0.875em;
    color: #004d35;
    outline: none;
    text-align: center;
    cursor: pointer;
    border: solid 1px #004d35;
    &:hover {
      color: #fff;
    }
    &.disabled {
      cursor: not-allowed;
      color: #afbcba;
      border: solid 1px #afbcba;
      &:hover {
        color: #afbcba;
        background-color: initial;
      }
    }
  }
  .login-error-container {
    margin-top: 16px;
    height: 14px;
    .login-error {
      font-size: 10px;
      line-height: 14px;
      color: #d22128;
      font-weight: 400;
    }
  }
}
.dropdown {
  flex: 1;
  max-width: 210px;
  // min-width: 100px;
  display: inline-block;
  position: relative;
  margin: 0 5px 15px;
  .selected {
    width: 100%;
    padding: 6px 2px;
    margin: 4px 0;
    border-radius: 5px;
  }
  .label {
    font-size: 0.9rem;
    color: rgb(185, 185, 185);
  }
  &:hover .dropdown-content {
    display: grid;
    grid-template-columns: 1fr;
    &.color-picker {
      display: flex;
      flex-wrap: wrap;
      background: transparent;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    bottom: -42px;
    width: 100%;
    min-width: 180px;
    overflow: auto;
    background-color: $White;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.4);
    z-index: 999;
    .dropdown-item {
      cursor: pointer;
      font-size: 1rem;
      text-align: center;
      font-weight: 900;
    }
  }
  p {
    line-height: 1;
  }
  .color-picker {
    li {
      width: 30px;
      height: 30px;
      display: flex;
      &:hover {
        border: red 3px inset;
      }
    }
  }
  .size-picker {
    overflow: auto;
    max-height: 300px;
    li {
      min-width: 42px;
      min-height: 42px;
      padding: 4px;
      &:hover {
        border: red 2px solid;
      }
    }
  }
  .picker-error {
    font-size: 0.9rem;
    line-height: 2;
    color: rgb(185, 185, 185);
  }
  .selected-value {
    background-color: rgba(189, 0, 0, 0.2);
  }
}
.point-out {
  position: absolute;
  right: 0;
  top: 4px;
  background-color: transparent !important;
  &:hover svg path {
    fill: white !important;
  }
  &.active svg path {
    fill: orange !important;
  }
}
.section-id {
  left: 6px;
  top: 4px;
  color: #666;
  font-size: 11px;
  position: absolute;
  &.green {
    color: green;
  }
}
.section-divider {
  padding: 20px;
  position: relative;
  &:nth-child(even) {
    background-color: rgba(#fff, 0.05);
    border-top: 1px solid rgba(#fff, 0.1);
    border-bottom: 1px solid rgba(#fff, 0.1);
  }
}
.innerHtml {
  margin: 16px;
  border-radius: 16px;
  border: 1px solid rgba(#fff, 0.2);
  overflow: hidden;
}
.vacancy-form {
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 16px;
  border: 1px solid rgba(#fff, 0.2);
  overflow: hidden;
}

.top-bar {
  width: 100vw;
  height: 50px;
  background-color: #3e3e3e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .admin-logo {
    position: unset;
    transform: scale(0.7);
  }
}
.bar-button {
  padding: 6px 12px;
  border-radius: 4px;
  background-color: white;
  &:hover {
    background-color: #e0e0eb;
  }
  &.active {
    background-color: #cbcbdb;
  }
}
.pointy {
  cursor: pointer;
  & * {
    cursor: pointer;
  }
}
.hand {
  cursor: grab;
  * {
    cursor: grab;
  }
}
.inserter {
  gap: 10px;
  .insert-button {
    min-width: 32px;
    height: 32px;
    border-radius: 8px;
    padding: 0 8px;
    background-color: #3c80f6;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #156bff;
    }
    &.rem {
      background-color: #f63c3c;
      &:hover {
        background-color: #ff1515;
      }
    }
  }
}

.toggler {
  flex: 1;
  margin: 0 5px 15px;
  .label {
    font-size: 0.9rem;
    color: rgb(185, 185, 185);
  }
  .toggler-control {
    width: 100%;
    padding-top: 10px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    .toggler-button {
      min-width: 80px;
      padding: 8px 16px;
      p {
        font-size: 1rem;
      }
      &.selected {
        background-color: #f4be4f;
      }
      &:hover {
        background-color: #e0e0eb;
      }
    }
  }
}
.icon-button {
  width: 24px;
  height: 24px;
  padding: 4px;
  margin: 2px;
  border-radius: 50%;
  &:hover {
    background-color: rgba(#fff, 0.2);
  }
  svg path {
    fill: white;
  }
}
.svg-button {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  &:hover {
    background-color: rgba(#fff, 0.2);
  }
}
.inserter-parent {
  .inserter,
  .icon-button {
    display: none;
  }
  &:hover {
    padding: 4px 20px;
    .inserter {
      display: flex;
    }
    .icon-button {
      display: block;
      align-self: center;
    }
  }
}

.posts-tab {
  height: 100%;
  .hidden {
    display: none;
  }
}

.on-off {
  width: 40px;
  height: 40px;
  padding: 8px;
  &:hover,
  &.active {
    background-color: #f4be4f;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.mail-divider {
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(#fff, 0.2);
  border-radius: 5px;
  font-family: sans-serif;
  position: relative;
  color: rgb(240, 240, 240);
  p {
    width: fit-content;
    word-break: break-all;
    hyphens: manual;
    color: black;
    span {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
    &.pointy:hover {
      color: blue;
    }
  }

  display: flex;
  flex-direction: row;
  .f6 {
    border-radius: 6px;
    background-color: #ccc;
  }
  .f1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: fit-content;
    justify-content: flex-end;
  }
  &:nth-child(even) {
    background-color: rgba(#fff, 0.5);
    border-top: 1px solid rgba(#fff, 0.1);
    border-bottom: 1px solid rgba(#fff, 0.1);
  }
  .mail-message {
    background-color: #eee;
    border-radius: 4px;
    padding: 10px;
  }
  .mail-btn {
    color: #fff;
    padding: 5px 12px;
    border-radius: 6px;
    user-select: none;
  }
  .mail-new {
    background-color: #f4be4f;
  }
  .mail-delete {
    cursor: pointer;
    background-color: red;
  }
}
.hidden-widget {
  display: none;
}
.three-col-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.two-col-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.cant-touch {
  pointer-events: none;
  user-select: none;
}
.cant-select {
  user-select: none;
}
.tooltip {
  position: relative;
  div:nth-child(2) {
    display: none;
    position: absolute;
    bottom: 120%;
    left: 0;
    width: max-content;
    padding: 4px;
    background-color: #1a2027;
    transform: translateZ(10);
    z-index: 999;
    border-radius: 4px;
    p {
      color: white;
      font-size: 11px;
    }
  }
  &.right-side div:nth-child(2) {
    left: unset;
    right: 0;
  }
  div:nth-child(1):hover ~ div {
    display: block;
  }
}

.language-picker {
  margin: 0 8px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  border: 1px solid grey;
  .language-tab {
    border-radius: 4px;
    padding: 2px 8px;
    span {
      color: white;
    }
    &.active {
      background-color: white;
      span {
        color: black;
      }
    }
  }
}
.admin-logo {
  position: fixed;
  top: 32px;
  left: 32px;
}
.file-delete {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: red;
  z-index: 99;
  position: relative;
  transition: all 0.3s ease-out 0.3s;
  &::after,
  &::before {
    content: "";
    top: 7px;
    left: 3px;
    width: 10px;
    height: 2px;
    background-color: white;
    position: absolute;
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.upload-error {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: red;
  position: relative;
  &:after {
    content: "!";
    position: absolute;
    color: white;
    left: 7px;
  }
}
.progress-bar {
  width: 100px;
  height: 18px;
  position: relative;
  border-radius: 10px;

  & div {
    width: var(--p);
    height: 18px;
    padding: 2px;
    color: $White;
    font-size: 12px;
    position: absolute;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  }
}
.analytics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .analytics-box {
    min-width: 34px;
    padding: 4px;
    display: flex;
    margin-bottom: 4px;
    border: 1px solid #fff1;
    p {
      text-align: center;
      user-select: none;
    }
  }
  .date-box:hover {
    background-color: #000000;
  }
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  td,
  th {
    text-align: center;
    padding: 8px;
  }

  td {
    border-right: 1px solid #000;
    font-size: 12px;
  }

  thead th {
    color: #ffffff;
    background: #82c3af;
  }

  thead th:nth-child(odd) {
    color: #ffffff;
  }

  tr:nth-child(even) {
    background: rgb(99, 99, 99);
  }
}
