$Blend: var(--yellow);
$White: var(--white);
.color-yellow {
  color: $Blend;
}
.color-after-yellow::after,
.color-before-yellow::before {
  color: $Blend;
}
.bg-after-yellow::after,
.bg-before-yellow::before,
.bg-yellow,
.hover-bg-yellow:hover {
  background-color: $Blend;
}
.fill-yellow {
  fill: $Blend;
  & * {
    fill: $Blend;
  }
}
.bg-gu-yellow {
  background: linear-gradient(180deg, $Blend 0%, $White 100%);
}
.bg-gd-yellow {
  background: linear-gradient(0deg, $Blend 0%, $White 100%);
}
.scroll-yellow {
  border-color: $Blend !important;
  &::before {
    background-color: $Blend !important;
  }
}
.fade-down-yellow {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, $Blend 0%, transparent 100%);
  }
}
.border-color-yellow {
  border-color: $Blend;
}
.hover-border-color-yellow:hover ~ div.labels {
  &::before,
  &::after {
    border: 1px solid $Blend;
  }
}
