$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);

.animated-link {
  .title {
    display: inline-block;
    overflow-y: hidden;
    padding-right: 4px;
    span {
      position: relative;
      display: inline-block;
      color: transparent;
      width: max-content;
      padding-right: 12px;
      // overflow-x: visible;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
      &:after,
      &:before {
        content: attr(data-text);
        display: block;
        position: absolute;
        width: max-content;
        padding-right: 12px;
      }
      &:before {
        top: 0;
        transform: skewY(0);
        transform-origin: right bottom;
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      }
      &:after {
        top: 105%;
        transform: skewY(7deg);
        transform-origin: left top;
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
    &:hover {
      span {
        transform: translateY(-105%);
        &::before {
          transform: skewY(7deg);
        }
        &::after {
          transform: skewY(0);
        }
      }
    }
  }
}
.m,
.p,
.mb,
.mv {
  // nothing
}
// margin
.m0 {
  margin: 0;
}
.m2 {
  margin: 2px;
}
.m4 {
  margin: 4px;
}
.m6 {
  margin: 6px;
}
.m8 {
  margin: 8px;
}
.m12 {
  margin: 12px;
}
.m16 {
  margin: 16px;
}
.m24 {
  margin: 24px;
}
.m32 {
  margin: 32px;
}
// margin bottom
.mb0 {
  margin-bottom: 0;
}
.mb2 {
  margin-bottom: 2px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb32 {
  margin-bottom: 32px;
}
// margin left
.ml0 {
  margin-left: 0;
}
.ml2 {
  margin-left: 2px;
}
.ml4 {
  margin-left: 4px;
}
.ml6 {
  margin-left: 6px;
}
.ml8 {
  margin-left: 8px;
}
.ml12 {
  margin-left: 12px;
}
.ml16 {
  margin-left: 16px;
}
.ml24 {
  margin-left: 24px;
}
.ml32 {
  margin-left: 32px;
}
// margin vertical
.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mv2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.mv4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.mv6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.mv8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mv12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mv16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mv24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mv32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
// padding
.p0 {
  padding: 0;
}
.p2 {
  padding: 2px;
}
.p4 {
  padding: 4px;
}
.p6 {
  padding: 6px;
}
.p8 {
  padding: 8px;
}
.p12 {
  padding: 12px;
}
.p16 {
  padding: 16px;
}
.p24 {
  padding: 24px;
}
.p32 {
  padding: 32px;
}
// padding right
.pr0 {
  padding-right: 0;
}
.pr2 {
  padding-right: 2px;
}
.pr4 {
  padding-right: 4px;
}
.pr6 {
  padding-right: 6px;
}
.pr8 {
  padding-right: 8px;
}
.pr12 {
  padding-right: 12px;
}
.pr16 {
  padding-right: 16px;
}
.pr24 {
  padding-right: 24px;
}
.pr32 {
  padding-right: 32px;
}
// gap
.gap0 {
  gap: 0;
}
.gap2 {
  gap: 2px;
}
.gap4 {
  gap: 4px;
}
.gap6 {
  gap: 6px;
}
.gap8 {
  gap: 8px;
}
.gap12 {
  gap: 12px;
}
.gap16 {
  gap: 16px;
}
.gap24 {
  gap: 24px;
}
.gap32 {
  gap: 32px;
}

.up-0 {
  z-index: 0;
}
.up-1 {
  z-index: 1;
}
.up-2 {
  z-index: 2;
}
.up-3 {
  z-index: 3;
}
.up-4 {
  z-index: 4;
}
.up-5 {
  z-index: 5;
}
.up-6 {
  z-index: 6;
}
.up-7 {
  z-index: 07;
}
.up-8 {
  z-index: 8;
}
.up-9 {
  z-index: 9;
}
.up-10 {
  z-index: 10;
}

.floor-0 {
  z-index: -0;
}
.floor-1 {
  z-index: -1;
}
.floor-2 {
  z-index: -2;
}
.floor-3 {
  z-index: -3;
}
.floor-4 {
  z-index: -4;
}
.floor-5 {
  z-index: -5;
}
.floor-6 {
  z-index: -6;
}
.floor-7 {
  z-index: -07;
}
.floor-8 {
  z-index: -8;
}
.floor-9 {
  z-index: -9;
}
.floor-10 {
  z-index: -10;
}
// border width
.bw1 {
  border-width: 1px;
}
.bw2 {
  border-width: 2px;
}
.bw3 {
  border-width: 3px;
}
.bw4 {
  border-width: 4px;
}
.bw5 {
  border-width: 5px;
}

.transition-container {
  //
  .transition-layer {
    position: fixed;
    width: 100%;
    height: 0vh;
    left: 0;
    right: 0;
    bottom: 0;
    background: $NavyGreen;
    transition: height 0.3s cubic-bezier(0.6, 0.05, 0.4, 1);
    z-index: 9999;

    .main-line {
      // display: none;
      float: left;
      clear: both;
      height: 12rem;
      white-space: nowrap;
      .letter-box {
        height: 100%;
        font-size: 10rem;
        overflow: hidden;
        position: relative;
        pointer-events: none;
        display: inline-block;
        text-transform: left center;
        transform: translate3d(0.1em, 0, 0) rotate(5deg);
        transition: transform 1.2s cubic-bezier(0.52, 0.175, 0.04, 0.98);
        .letter-inner {
          color: $White;
          position: relative;
          display: inline-block;
          transform-origin: top;
          text-transform: uppercase;
          transform: translate3d(105%, 0, 0);
          transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }

    &.active {
      top: 0;
      bottom: unset;
      height: 100vh;
      .main-line {
        display: flex;
        .letter-box {
          transform: translate3d(0, 0, 0) rotate(0);
          transition: transform 1.2s cubic-bezier(0.52, 0.175, 0.04, 0.98);
          .letter-inner {
            transform: translate3d(0, 0, 0);
            transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          &:nth-child(1) {
            transition-delay: 1.15s;
            .letter-inner {
              transition-delay: 1.1s;
            }
          }
          &:nth-child(2) {
            transition-delay: 1.212s;
            .letter-inner {
              transition-delay: 1.141s;
            }
          }
          &:nth-child(3) {
            transition-delay: 1.259s;
            .letter-inner {
              transition-delay: 1.173s;
            }
          }
          &:nth-child(4) {
            transition-delay: 1.3s;
            .letter-inner {
              transition-delay: 1.2s;
            }
          }
          &:nth-child(5) {
            transition-delay: 1.335s;
            .letter-inner {
              transition-delay: 1.223s;
            }
          }
          &:nth-child(6) {
            transition-delay: 1.367s;
            .letter-inner {
              transition-delay: 1.244s;
            }
          }
          &:nth-child(7) {
            transition-delay: 1.396s;
            .letter-inner {
              transition-delay: 1.264s;
            }
          }
          &:nth-child(8) {
            transition-delay: 1.424s;
            .letter-inner {
              transition-delay: 1.282s;
            }
          }
          &:nth-child(9) {
            transition-delay: 1.45s;
            .letter-inner {
              transition-delay: 1.3s;
            }
          }
          &:nth-child(10) {
            transition-delay: 1.474s;
            .letter-inner {
              transition-delay: 1.316s;
            }
          }
          &:nth-child(11) {
            transition-delay: 1.497s;
            .letter-inner {
              transition-delay: 1.331s;
            }
          }
          &:nth-child(12) {
            transition-delay: 1.519s;
            .letter-inner {
              transition-delay: 1.346s;
            }
          }
          &:nth-child(13) {
            transition-delay: 1.54s;
            .letter-inner {
              transition-delay: 1.36s;
            }
          }
          &:nth-child(14) {
            transition-delay: 1.561s;
            .letter-inner {
              transition-delay: 1.374s;
            }
          }
          &:nth-child(15) {
            transition-delay: 1.58s;
            .letter-inner {
              transition-delay: 1.387s;
            }
          }
        }
      }
    }
  }
}
