$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$MidnightBlue: var(--midnight-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
$Orange: var(--orange);
$Font: var(--font);
@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 1200px) {
  html,
  body,
  input,
  textarea,
  a {
    cursor: none;
  }
}

ul {
  list-style: none;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
input,
li {
  font-family: $Font;
}
p,
span {
  white-space: pre-line;
}

a {
  text-decoration: none;
}

.width-wrapper {
  max-width: 100%;
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

// horizontal scroll hide
.hsh::-webkit-scrollbar {
  display: none;
}

.inh {
  font-size: inherit;
  color: inherit;
}

.italic {
  font-style: italic !important;
}
.underlined {
  text-decoration: underline !important;
}
.thin {
  font-weight: 100 !important;
}
.extra-light {
  font-weight: 200 !important;
}
.light {
  font-weight: 300 !important;
}
.regular {
  font-weight: 400 !important;
}
.medium {
  font-weight: 500 !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.bold {
  font-weight: 700 !important;
}
.extra-bold {
  font-weight: 800 !important;
}
.black {
  font-weight: 900 !important;
}
.H1 {
  font-size: 120px;
  font-weight: 300;
  line-height: 108px;
  letter-spacing: 0;
}

.H2 {
  font-style: normal;
  font-weight: bold;
  font-size: 104px;
  line-height: 122px;
}

.H3 {
  font-size: 60px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: 0;
}

.H4 {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0;
}

.H5 {
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
}

.H6 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
  letter-spacing: 0;
}

.Sub {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
}

.Sub2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
}

.Body {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.015em;
}

.Body2 {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
}

.Button {
  font-size: 14px;
}

.Cap {
  font-size: 12px;
}

.Small-11 {
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
}

.None {
  font-size: 12px;
}

.H-mob {
  font-style: italic;
  font-weight: 300;
  font-size: 40px;
  line-height: 47px;
}

.Link {
  color: $NavyGreen;
  font-style: italic !important;
  text-decoration: underline !important;
  pointer-events: all !important;
}

.Overline {
  font-size: 10px;
}

.color-transparent {
  color: transparent;
}

.color-bright-green {
  color: $BrightGreen;
}

.color-light-green {
  color: $LightGreen;
}

.color-navy-blue {
  color: $NavyBlue;
}

.color-bright-blue {
  color: $BrightBlue;
}

.color-light-blue {
  color: $LightBlue;
}

.color-soft-blue {
  color: $SoftBlue;
}

.color-grey-blue {
  color: $GreyBlue;
}

.color-midnight-blue {
  color: $MidnightBlue;
}

.color-grey {
  color: $JustGrey;
}

.color-light-grey {
  color: $LightGrey;
}

.color-yellow {
  color: $Yellow;
}

.color-orange {
  color: $Orange;
}

.color-text-primary {
  color: $JustGrey;
}

.color-navy-green {
  color: $NavyGreen;
}

.container {
  flex: 1;
  display: flex;
  max-width: 1280px;
}
.mini-container {
  flex: 1;
  display: flex;
  max-width: 820px;
}

.full-width {
  width: 100%;
}

.screen-width {
  width: 100vw;
  @media screen and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
    width: 125vw;
  }
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}
.align-right {
  align-items: flex-end;
}
.align-left {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: flex-end;
}
.justify-left {
  justify-content: flex-start;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.center {
  justify-content: center;
  align-items: center;
}

.wrap-word {
  overflow-wrap: break-word;
}

.block {
  display: block;
}

.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.f5 {
  flex: 5;
}
.f6 {
  flex: 6;
}
.f7 {
  flex: 7;
}
.f8 {
  flex: 8;
}
.f9 {
  flex: 9;
}
.f10 {
  flex: 10;
}
.f11 {
  flex: 11;
}
.f12 {
  flex: 12;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.frc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.frr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fcc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.relative {
  position: relative;
}
.fade {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fade 1s ease-out 10ms forwards;
}

.pulse {
  width: fit-content;
  height: fit-content;
  @media screen and (min-width: 1200px) and (orientation: portrait) {
    &:hover {
      transform-origin: center;
      animation: pulse 0.5s ease infinite alternate-reverse;
    }
  }
}

.non-selectable {
  user-select: none;
}

@keyframes fade {
  99% {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  100% {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  to {
    transform-origin: center;
    transform: scale(1.05);
  }
}
@media screen and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  html,
  body,
  input,
  textarea,
  a {
    cursor: none;
  }
}
