$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
.text-input {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .input {
    background: transparent;
    padding: 14px 16px;
    color: $JustGrey;
    border: 1px solid $JustGrey;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.015em;
    margin-top: 8px;
    outline: 0;
    &:focus {
      padding: 13px 15px;
      border: 2px solid $NavyGreen;
      color: $NavyGreen;
    }
  }
  .multi {
    min-height: 100px;
    max-width: 100%;
    resize: none;
    overflow: hidden;
  }
  .input-error {
    // background-color: $White;
    top: 35px;
    height: 40px;
    bottom: 0;
    left: 16px;
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    pointer-events: none;
    p::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url("../assets/error_outline.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .letters-counter {
    position: absolute;
    bottom: 16px;
    right: 14px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }
  &.error {
    input,
    textarea {
      color: transparent;
    }
  }
}
.checkbox-root {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  .checkbox-error {
    position: absolute;
    right: calc(100% + 10px);
    width: max-content;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: $NavyBlue;
  }
}
.checkbox {
  margin-right: 8px;
  position: relative;
  input,
  svg {
    width: 16px;
    height: 16px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 1px $JustGrey;
    &:checked {
      border: $JustGrey;
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: $JustGrey;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    transform: scale(1) translateZ(0);
  }
  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }
  &.bounce {
    stroke: $JustGrey;
    input {
      &:checked {
        --s: 11px;
        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }
    svg {
      scale: 0;
    }
  }
}
.download-button:hover {
  text-decoration-line: underline;
  svg path {
    fill: $NavyGreen;
  }
}

.dropzone-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  animation: show 1s ease 0s 1 normal forwards;
  @media screen and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
    height: 125vh;
  }
}

.dropzone {
  &.disabled {
    background-color: $SoftBlue !important;
    &:hover {
      background-color: $SoftBlue !important;
    }
    p,
    path {
      color: $LightGrey;
      fill: $LightGrey;
    }
    .download-button:hover {
      text-decoration-line: none;
    }
  }
  &:hover {
    background-color: $LightGreen !important;
  }
}
.dropzone-error {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 2px 6px;
  background-color: $BrightBlue;
  border-radius: 4px;
  align-items: baseline;
  position: absolute;
  top: 38px;
  left: 8px;
  p {
    line-height: 13px;
    font-size: 9px;
  }
}
.single-file:first-child {
  margin-top: 12px;
}
.delete-button {
  width: 18px;
  height: 18px;
  position: relative;
  padding: 4px;
  &::after,
  &::before {
    content: "";
    top: 8px;
    left: 3px;
    width: 12px;
    height: 2px;
    position: absolute;
    background-color: $LightGrey;
  }

  &:hover {
    &::after,
    &::before {
      background-color: $NavyGreen;
    }
  }

  &::after {
    transform: rotate(135deg);
  }
  &::before {
    transform: rotate(-135deg);
  }
}

.text-input .letters-counter {
  font-size: 10px !important;
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (max-width: 1536px) and (min-width: 1201px) {
}
@media screen and (max-width: 1200px) and (min-width: 481px) {
  .dropzone {
    &:hover {
      background-color: $SoftBlue !important;
    }
  }
  .dropzone-error {
    position: static;
    margin-top: 16px;
  }
  .single-file .f1 {
    flex: 0;
  }
  .checkbox-root {
    .checkbox {
      input,
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .download-button:hover {
    text-decoration-line: none;
    svg path {
      fill: $LightGrey;
    }
  }
}
@media screen and (max-width: 1229px) and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  .dropzone {
    &:hover {
      background-color: $LightGreen !important;
    }
  }
  .dropzone-error {
    position: absolute;
    margin-top: unset;
  }
  .single-file .f1 {
    flex: 1;
  }
  .checkbox-root {
    .checkbox {
      input,
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .download-button:hover {
    text-decoration-line: underline;
    svg path {
      fill: $NavyGreen;
    }
  }
}
@media (max-width: 480px) {
  .dropzone:hover {
    background-color: $SoftBlue !important;
  }
  .dropzone-error {
    position: static;
    margin-top: 16px;
  }
  .single-file .f1 {
    max-width: 18px;
  }
  .dropzone-description {
    flex-direction: column;
  }
  .text-input {
    .Sub2 {
      font-size: 15px;
      line-height: 18px;
    }
    .letters-counter {
      font-size: 10px !important;
    }
  }

  .checkbox-root {
    .checkbox {
      input,
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .checkbox-error {
      left: 20px;
      bottom: -20px;
    }
  }
  .download-button:hover {
    text-decoration-line: none;
  }
}
