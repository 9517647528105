$Blend: var(--black);
$White: var(--white);
.color-black {
  color: $Blend;
}
.color-after-black::after,
.color-before-black::before {
  color: $Blend;
}
.bg-black,
.hover-bg-black:hover {
  background-color: $Blend;
}
.fill-black {
  fill: $Blend;
  & * {
    fill: $Blend;
  }
}
.bg-gu-black {
  background: linear-gradient(180deg, $Blend 0%, $White 100%);
}
.bg-gd-black {
  background: linear-gradient(0deg, $Blend 0%, $White 100%);
}
.scroll-black {
  border-color: $Blend !important;
  &::before {
    background-color: $Blend !important;
  }
}
.fade-down-black {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, $Blend 0%, transparent 100%);
  }
}
.border-color-black {
  border-color: $Blend;
}
.hover-border-color-black:hover ~ div.labels {
  &::before,
  &::after {
    border: 1px solid $Blend;
  }
}
