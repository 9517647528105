$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
.not-found-root {
  width: 100vw;
  height: calc(100vh - 140px);
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .t404 {
    display: flex;
    width: 1280px;
    align-self: center;
    flex-direction: row;
    .not-found {
      position: absolute;
      bottom: -140px;
      right: 0;
      font-weight: 100;
      font-size: 700px;
    }
  }
  .message {
    margin-right: 26px;
    justify-self: flex-start;
    .back-home {
      margin-top: 40px;
      width: max-content;
      .back-home-btn {
        background-image: url("../assets/back-home.svg");
        width: 180px;
        aspect-ratio: 1 / 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &:hover {
          background-image: url("../assets/back-home-fill.svg");
        }
      }
    }
  }
}
@media screen and (min-width: 1201px) and (-webkit-device-pixel-ratio: 1.25) {
  .not-found-root {
    width: 125vw;
    height: calc(125vh - 140px);
    .t404 .not-found {
      font-size: 700px !important;
    }
  }
}
@media screen and (max-width: 1536px) and (min-width: 1201px) {
  .not-found-root .t404 .not-found {
    font-size: 600px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 480px) {
  .not-found-root {
    height: calc(100vh - 80px);
    .message {
      align-self: flex-end;
      margin-left: 64px;
      margin-bottom: 60px;
      .back-home {
        margin-top: 32px;
        .back-home-btn {
          width: 100px;
        }
      }
    }
    .t404 {
      flex: 1;
      justify-content: space-between;
      position: relative;
      width: 100%;
      .not-found {
        right: 0;
        top: 65px;
        position: absolute;
        font-weight: 200;
        font-size: 300px;
        line-height: 70%;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        bottom: unset;
        @media (orientation: portrait) {
          font-size: 500px;
        }
      }
    }
  }
}
@media screen and (max-width: 1229px) and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  .not-found-root {
    width: 125vw;
    height: calc(125vh - 140px);
    .message {
      align-self: unset;
      justify-self: flex-start;
      margin-left: unset;
      margin-bottom: unset;
      margin-right: 26px;
      .back-home {
        margin-top: 40px;
        width: max-content;
        .back-home-btn {
          width: 180px;
        }
      }
    }
    .t404 {
      flex: unset;
      justify-content: unset;
      flex-direction: row;
      position: unset;
      align-self: center;
      width: 1280px;
      .not-found {
        right: 0;
        top: unset;
        bottom: -140px;
        font-weight: 100;
        font-size: 600px;
        line-height: unset;
        writing-mode: unset;
        transform: unset;
      }
    }
  }
}
@media (max-width: 480px) {
  .not-found-root {
    height: calc(100vh - 80px);
    justify-content: flex-end;
    .t404 {
      flex-direction: column;
      width: unset;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: -24px;
      position: relative;
      .message {
        align-self: flex-start;
        margin: 0 32px 340px;
        .back-home {
          margin-top: 60px;
          .back-home-btn {
            width: 100px;
          }
        }
      }
      .not-found {
        position: absolute;
        right: 0;
        bottom: -24px;
        font-weight: 200;
        font-size: 250px;
        line-height: 70%;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
      }
    }
  }
}
