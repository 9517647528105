$NavyGreen: var(--navy-green);
$BrightGreen: var(--bright-green);
$LightGreen: var(--light-green);
$NavyBlue: var(--navy-blue);
$BrightBlue: var(--bright-blue);
$LightBlue: var(--light-blue);
$SoftBlue: var(--soft-blue);
$GreyBlue: var(--grey-blue);
$MidnightBlue: var(--midnight-blue);
$JustGrey: var(--just-grey);
$LightGrey: var(--light-grey);
$Yellow: var(--yellow);
$White: var(--white);
$Black: var(--black);
$Font: var(--font);
.cookie-popup {
  width: 460px;
  height: 257px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding: 20px 30px 32px;
  visibility: hidden;
  animation: slide-down 1s linear both;
  &.wide {
    width: 550px;
    height: 195px;
    & .popup-title {
      width: 442px;
      margin-bottom: 8px;
      margin-right: 24px;
    }
    & > .popup-description {
      width: 490px;
      margin-bottom: 0;
    }
  }
  &.hidden {
    display: none;
  }
  @keyframes slide-down {
    0% {
      visibility: hidden;
      transform: translateY(100px);
    }

    100% {
      visibility: visible;
      transform: translateY(0);
    }
  }
  .button {
    font-family: $Font;
    padding-bottom: 6px;
    font-style: italic;
    width: fit-content;
    margin-left: auto;
    line-height: 21px;
    font-size: 18px;
    &.color-navy-green:hover {
      border-bottom: 1px solid $NavyGreen;
    }
    &.color-white:hover {
      border-bottom: 1px solid $White;
    }
    &.bold {
      margin-left: 32px;
    }
    &.bold.color-navy-green:hover {
      border-bottom: 2px solid $NavyGreen;
    }
    &.bold.color-white:hover {
      border-bottom: 2px solid $White;
    }
  }
}
.popup-title {
  margin-bottom: 8px;
}
.popup-description {
  width: 400px;
  margin-bottom: 32px;
}
.popup-link {
  border-bottom: 1px solid;
  &.color-navy-green {
    border-color: $NavyGreen;
  }
  &.color-white {
    border-color: $White;
  }
}

.close-button {
  padding: 10px;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  div {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  span {
    position: absolute;
    top: 50%;
    left: 4px;
    display: block;
    width: 15px;
    height: 1px;
  }
  span:first-of-type {
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  }
  span:last-of-type {
    transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
  }
  &.button-navy-green {
    border: 1px solid $NavyGreen;
    span {
      background: $NavyGreen;
    }
  }
  &.button-white {
    border: 1px solid $White;
    span {
      background: $White;
    }
  }
}

@media screen and (max-width: 1536px) and (min-width: 1201px) {
}
@media screen and (max-width: 1200px) and (min-width: 481px) {
}
@media (max-width: 480px) {
}
