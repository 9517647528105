$Blend: var(--navy-green);
$White: var(--white);
.color-navy-green {
  color: $Blend;
}
.color-after-navy-green::after,
.color-before-navy-green::before {
  color: $Blend;
}
.bg-after-navy-green::after,
.bg-before-navy-green::before,
.bg-navy-green,
.hover-bg-navy-green:hover {
  background-color: $Blend;
}
.fill-navy-green {
  fill: $Blend;
  & * {
    fill: $Blend;
  }
}
.bg-gu-navy-green {
  background: linear-gradient(180deg, $Blend 0%, $White 100%);
}
.bg-gd-navy-green {
  background: linear-gradient(0deg, $Blend 0%, $White 100%);
}
.scroll-navy-green {
  border-color: $Blend !important;
  &::before {
    background-color: $Blend !important;
  }
}
.fade-down-navy-green {
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, $Blend 0%, transparent 100%);
  }
}
.border-color-navy-green {
  border-color: $Blend;
}
.hover-border-color-navy-green:hover ~ div.labels {
  &::before,
  &::after {
    border: 1px solid $Blend;
  }
}
